module services {
    export module tariff {
        export class valuationCodeService implements interfaces.tariff.IValuationCodeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getForDropdown(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ValuationCode/GetForDropdown", {
                    countryId: countryId
                });
            }
        }
    }
    angular.module("app").service("valuationCodeService", services.tariff.valuationCodeService);
}